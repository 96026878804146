import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';
import { useIntl } from 'react-intl';
import { useGetSelectedFilterOptionReferences } from '@aph/components/common/product-listing/filter.component';
import { Breadcrumbs } from '@aph/components/layouts/breadcrumb.component';
import { PageLayout } from '@aph/components/layouts/page-layout.component';
import { ListPageHeader } from '@aph/components/listpage-header/listpage-header.component';
import { useGetSEOPropsWithFallbacks } from '@aph/hooks/use-get-seo-props-with-fallbacks/use-get-seo-props-with-fallbacks';
import { isErrorWithStatus } from '@aph/utilities/errors/is-error-with-status';
import { isRedirectData } from '@aph/utilities/errors/types';
import { withPageErrorHandler } from '@aph/utilities/with-page-error-handler';
import { ArticlePanel } from '~/articles/components/article-panel/article-panel';
import { SidebarWithCategoryNavigation } from '~/articles/components/sidebar-with-category-navigation';
import { useGetCategoryNavigation } from '~/articles/hooks/use-get-category-navigation';
import { useGetSortByParam } from '~/articles/hooks/use-get-sort-by-param/use-get-sort-by-param';
import { parseFilterParams } from '~/articles/utilities/article-filter/article-filter';
import { ContentApiClient } from '~/contentful/api/ContentApiClient';
import { InfoBar } from '~/contentful/components/info-bar/info-bar';
import type { IBrandPage, IBrandPageFields, IInfoBar } from '~/contentful/types';
import { mapContentfulSEOToNextSEO } from '~/contentful/utilities/next-seo-from-contentful';
import { logger } from '~/logging';
import { getServerSidePropsWithPromotedLinks } from '~/server/get-server-side-props';
import { ArticleListing } from '../../articles/components/article-listing/article-listing';
import { mapDataToArticleListingProps } from '../../articles/components/article-listing/map-data-to-article-listing-props';
import {
  prefetchUseGetArticlesQuery,
  useGetArticles,
} from '../../articles/hooks/use-get-articles/use-get-articles';
import { useGetFilterAndSortingOptions } from '../../articles/hooks/use-get-filter-and-sorting-options/use-get-filter-and-sorting-options';
import { ContentfulImage } from '../../contentful/components/next-contentful-image/next-contentful-image';

const BrandPage: NextPage<InferGetServerSidePropsType<typeof getServerSideProps>> = ({
  contentfulData,
  infoBar,
}) => {
  const { formatMessage } = useIntl();
  const sortBy = useGetSortByParam();
  const theme = useTheme();

  const selectedFilterOptionReferences = useGetSelectedFilterOptionReferences();

  const { pageTitle, bodyCopy, image, seo, slug } = contentfulData;

  const articleListingProps = mapDataToArticleListingProps({
    listingResult: useGetArticles({
      variables: {
        slug,
        byResourceType: 'brand',
        sortBy,
        filterBy: selectedFilterOptionReferences,
      },
    }),
    filterAndSortingResult: useGetFilterAndSortingOptions({
      variables: {
        slug,
        byResourceType: 'brand',
      },
    }),
    eventListName: 'Detaljsida varumärken',
    sortByParams: sortBy,
  });

  const seoProps = useGetSEOPropsWithFallbacks({
    pageTitle,
    seoProps: mapContentfulSEOToNextSEO(seo?.fields),
  });

  return (
    <PageLayout
      seo={seoProps}
      top={infoBar ? <InfoBar data={infoBar.fields} /> : undefined}
      hero={
        <Stack direction="column" rowGap={4}>
          <Breadcrumbs
            menuItems={[]}
            pathsForBreadcrumbsMissingInMenuItems={[
              {
                name: formatMessage({ id: 'BRAND_PAGE.BREADCRUMB' }),
                url: '/varumarken/',
              },
            ]}
          />
          <Stack direction={{ xs: 'column', md: 'row' }} gap={3}>
            {image && (
              <Stack
                borderRadius={{ xs: 4, sm: 6 }}
                overflow="hidden"
                width={{ xs: '100%', md: '50%' }}
                height="fit-content"
              >
                <ContentfulImage
                  src={image}
                  alt={image?.fields.description || image.fields.title}
                  width={image.fields.file.details.image?.width}
                  height={image.fields.file.details.image?.height}
                  sizes={`(min-width:${theme.breakpoints.values.md}px) 50vw, 100vw`}
                  priority
                  quality={80}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                  }}
                />
              </Stack>
            )}
            <Box width={{ xs: '100%', md: '50%' }}>
              <ListPageHeader title={pageTitle} description={bodyCopy} fullWidth />
            </Box>
          </Stack>
        </Stack>
      }
      sidebar={<SidebarWithCategoryNavigation />}
    >
      <Stack spacing={4}>
        {Array.isArray(contentfulData.relatedArticles) &&
        contentfulData.relatedArticles.length > 0 ? (
          <ArticlePanel
            articleCodes={contentfulData.relatedArticles}
            title="Rekommenderade"
            listName="Detaljsida varumärken: Rekommenderade"
          />
        ) : null}

        <ArticleListing {...articleListingProps} />
      </Stack>
    </PageLayout>
  );
};

export const getServerSideProps = getServerSidePropsWithPromotedLinks(
  async ({ params, query, res, req, draftMode, trpc }) => {
    if (params?.slug) {
      const slug = ['varumarken', params.slug].join('/');

      res.setHeader(
        'Cache-Control',
        draftMode ? 'no-cache' : 'public, s-maxage=20, stale-while-revalidate=60',
      );

      try {
        const contentApiClient = new ContentApiClient({ headers: req.headers });
        const { queryClient } = trpc;

        const getServerSideData = Promise.all([
          contentApiClient.getPage<IBrandPage>({
            slug,
            contentType: 'brandPage',
            preview: draftMode,
          }),
          contentApiClient
            .getEntityBySlug<IInfoBar>({
              slug: 'brands-info-bar',
              contentType: 'infoBar',
            })
            .catch(() => null),
          queryClient.prefetchQuery({
            queryKey: useGetCategoryNavigation.getKey({ slug: 'varumärke' }),
            queryFn: () =>
              useGetCategoryNavigation.fetcher(
                { slug: 'produkter', levels: 1 },
                { meta: { headers: req.headers } },
              ),
          }),

          queryClient.prefetchInfiniteQuery(
            prefetchUseGetArticlesQuery({
              variables: {
                slug,
                byResourceType: 'brand',
                sortBy: query?.sortBy?.toString(),
                filterBy: parseFilterParams(query.filter),
              },
              req,
              page: query.page,
            }),
          ),
        ]);

        const [brandPage, infoBar] = await getServerSideData;

        // handle redirect
        if (isRedirectData(brandPage)) {
          return {
            redirect: {
              permanent: true,
              destination: brandPage.location,
            },
          };
        }

        return {
          props: {
            contentfulData: brandPage.fields,
            infoBar,
          },
        };
      } catch (error: unknown) {
        logger.error(error, `Error in getServerSideProps with slug "${slug}"`);

        if (isErrorWithStatus(error)) {
          if (error.status >= 500) {
            res.statusCode = 503;
            return {
              props: {
                error: { status: 503 },
                contentfulData: null as unknown as IBrandPageFields,
                infoBar: null,
              },
            };
          }
        }

        return {
          notFound: true,
        };
      }
    }

    return {
      notFound: true,
    };
  },
) satisfies GetServerSideProps<{ contentfulData: IBrandPageFields; infoBar: IInfoBar | null }>;

export default withPageErrorHandler(BrandPage);
